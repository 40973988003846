import { Typography } from "@mui/material";
import React from "react";
import Search from "../../assets/images/search.svg";
import Filter from "../../assets/images/filter.svg";
import SearchInput from "../common/SearchInput";
import Dropdown from "../common/Dropdown";

const SearchBar = ({ length, query, setQuery }) => {
  return (
    <div className="flex flex-col lg:flex-row items-center justify-between mt-4">
      <Typography className="w-60 xl:w-1/2 font-bold text-lg">
        ({length}) Users
      </Typography>
      <div className="flex flex-col xl:flex-row items-center w-full xl:w-1/2">
        <div className="flex flex-row items-center mr-0 py-4 xl:mr-8">
          <img src={Search} alt="Search" className="mr-4" />
          <SearchInput
            placeholder={"Search Users"}
            query={query}
            setQuery={setQuery}
          />
        </div>
        <div className="flex flex-row items-center">
          <img src={Filter} alt="Search" className="mr-4" />
          <Dropdown />
        </div>
      </div>
    </div>
  );
};

export default SearchBar;
