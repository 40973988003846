import React from "react";
import ButtonLarge from "../auth/ButtonLarge";
import BoldText from "../typography/BoldText";
import { Typography } from "@mui/material";

const AddButton = ({ length }) => {
  return (
    <div className=" flex justify-between items-center">
      <Typography className="w-60 xl:w-1/2 font-bold text-lg">
        ({length}) Plans
      </Typography>
      <div>
        <ButtonLarge width={200}>Add New Plan</ButtonLarge>
      </div>
    </div>
  );
};

export default AddButton;
