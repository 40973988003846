import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_URL ?? "http://localhost:3000"; // Replace with your API base URL

const api = axios.create({
  baseURL: API_BASE_URL,
});

let navigator = null;

export const setNavigator = (_navigator) => {
  navigator = _navigator;
};

// Function to set the token in the request headers
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => response.data,
  (error) => {
    console.log("Error occurred in apiClient.js", error);
    if (error.response.status === 401) {
      localStorage.removeItem("token");
      if (navigator) navigator("/login", { replace: true });
    }
    return Promise.reject(error);
  }
);

export const authLogin = async (data) => {
  return await api.post("/auth/admin/login", data);
};

export const fetchUsers = async () => {
  return await api.get("/users/admin");
};

export const fetchStockDetails = async (symbol) => {
  return await api.get(`/stock-details/admin/${symbol}`);
};

export const fetchAllStockDetails = async () => {
  return await api.get(`/stock-details`);
};

export const fetchMonthlyStocks = async () => {
  return await api.get(`/stocks`);
};

export const updateStockDetail = async ({ symbol, isCompliant }) => {
  return await api.put(`/stock-details/${symbol}`, { isCompliant });
};

export const createStocks = async ({ stockDetails }) => {
  return await api.post("/stocks/admin", { stockDetails });
};

export const fetchPlans = async () => {
  return await api.get("/subscription-plan?isActive=true");
};
