import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { updateLoadingToast } from "../utils/helpers";
import { toast } from "react-toastify";
import { fetchPlans } from "../api/api";

export const getPlans = createAsyncThunk(
  "auth/fetchPlans",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      toast.loading("Fetching plans", { toastId: "fetch-plans" });
      const res = await fetchPlans();
      console.log(res);
      dispatch(setPlans(res));
      return res.data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const plansSlice = createSlice({
  name: "plans",
  initialState: {
    plans: [],
  },
  reducers: {
    setPlans: (state, action) => {
      state.plans = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPlans.fulfilled, (state, action) => {
      toast.dismiss("fetch-plans");
    });
    builder.addCase(getPlans.rejected, (state, action) => {
      updateLoadingToast(
        action.payload ?? "Error fetching users",
        "error",
        "fetch-plans"
      );
    });
  },
});

export const { setPlans } = plansSlice.actions;

export default plansSlice.reducer;
