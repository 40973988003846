import { useTheme } from "@emotion/react";
import { Typography } from "@mui/material";
import React from "react";
import halalLogoWhite from "../../assets/images/halalLogoWhite.svg";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetUser } from "../../redux/userSlice";

const LeftNav = ({ item }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const links = [
    {
      name: "Home",
      link: "/",
    },
    {
      name: "Users",
      link: "/users",
    },
    {
      name: "Buy Signals",
      link: "/signals",
    },
    {
      name: "Stocks",
      link: "/stocks",
    },
    {
      name: "Plans",
      link: "/plans",
    },
    {
      name: "Settings",
      link: "/settings",
    },
    {
      name: "Logout",
      action: () => {
        localStorage.removeItem("token");
        dispatch(resetUser());
        navigate("/login");
      },
    },
  ];
  return (
    <div
      className="flex flex-col p-12 fixed left-0 justify-between"
      style={{
        background: `linear-gradient(90deg, ${theme.palette.primary.main}, ${theme.palette.primary.light})`,
        height: "100vh",
      }}
    >
      <div className=" h-1/4">
        <img src={halalLogoWhite} alt="logo" className="w-44 object-cover" />
      </div>
      <div className="h-3/4">
        {links.map((link, index) => (
          <Link to={link?.link} onClick={link?.action} key={index}>
            <Typography
              className={`text-base py-4 cursor-pointer ${
                index === item ? "font-extrabold" : ""
              }`}
              sx={{
                color: theme.palette.text.white,
              }}
            >
              {link.name}
            </Typography>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default LeftNav;
