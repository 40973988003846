import React, { useEffect } from "react";
import Layout from "./Layout";
import { useDispatch, useSelector } from "react-redux";
import { getPlans } from "../redux/plansSlice";
import BoldText from "../components/typography/BoldText";
import PlansTable from "../components/plans/PlansTable";
import AddButton from "../components/plans/AddButton";

const Plans = () => {
  const { plans } = useSelector((state) => state.plans);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPlans());
  }, []);

  return (
    <Layout item={4}>
      <div className="p-10">
        <BoldText className={"text-4xl"}>Subscription Plans</BoldText>
        <AddButton length={plans?.length} />
        <PlansTable plans={plans} />
      </div>
    </Layout>
  );
};

export default Plans;
