import React, { useState } from "react";
import Dropdown from "../common/Dropdown";
import SearchInput from "../common/SearchInput";
import addBtn from "../../assets/images/addBtn.svg";
import { useDispatch, useSelector } from "react-redux";
import { findStock } from "../../redux/stocksSlice";
import { toast } from "react-toastify";

const SignalSearchBar = () => {
  const [query, setQuery] = useState("");
  const dispatch = useDispatch();
  const { stockDetails } = useSelector((state) => state.stocks);

  const updateStockDetails = async () => {
    if (!query) {
      toast.error("Please enter a stock symbol");
      return;
    }
    if (stockDetails.length > 6) {
      toast.warn("You have already selected seven stocks for current month");
      return;
    }
    if (
      stockDetails.some(
        (stock) => stock.symbol?.toLowerCase() === query.toLowerCase()
      )
    ) {
      toast.warn("Stock is already selected");
      return;
    }
    dispatch(findStock({ symbol: query }));
  };

  return (
    <div className="mt-6 flex-col lg:flex-row justify-between flex ">
      <div className="flex flex-row items-center">
        <SearchInput
          placeholder={"Search Stocks"}
          query={query}
          setQuery={setQuery}
          clasaName={"my-4 lg:my-0"}
          onSubmit={updateStockDetails}
        />
        <img
          src={addBtn}
          alt="Add"
          onClick={updateStockDetails}
          className="ml-4 w-6 cursor-pointer"
        />
      </div>
      <Dropdown className={"mr-0 lg:mr-6"} heading="Signals" />
    </div>
  );
};

export default SignalSearchBar;
