import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { formatDate } from "../../utils/helpers";
import { StyledTableCell, StyledTableRow } from "../common/Styled";

const PlansTable = ({ plans }) => {
  return (
    <TableContainer component={Paper} className="mt-8">
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Id</StyledTableCell>
            <StyledTableCell>Period</StyledTableCell>
            <StyledTableCell>Price</StyledTableCell>
            <StyledTableCell>Trial Period</StyledTableCell>
            <StyledTableCell>Currency</StyledTableCell>
            <StyledTableCell>Discount</StyledTableCell>
            <StyledTableCell>Date</StyledTableCell>
            <StyledTableCell>Status</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {plans?.map((row, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell>{index + 1}</StyledTableCell>
              <StyledTableCell>{row?.period}</StyledTableCell>
              <StyledTableCell>
                {row?.amount}
                {row?.currencySymbol}
              </StyledTableCell>
              <StyledTableCell>{"False"}</StyledTableCell>
              <StyledTableCell>{row?.currency}</StyledTableCell>
              <StyledTableCell>{"No"}</StyledTableCell>
              <StyledTableCell>{formatDate(row?.createdAt)}</StyledTableCell>
              <StyledTableCell>
                {row?.isActive ? "Active" : "Inactive"}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PlansTable;
