import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./userSlice";
import stocksSlice from "./stocksSlice";
import plansSlice from "./plansSlice";

export const store = configureStore({
  reducer: {
    user: userSlice,
    plans: plansSlice,
    stocks: stocksSlice,
  },
});
