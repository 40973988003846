import { createBrowserRouter } from "react-router-dom";
import ErrorPage from "../utils/error-page";
import Login from "../pages/Login";
import Home from "../pages/Home";
import Users from "../pages/Users";
import MonthlySignals from "../pages/MonthlySignals";
import Stocks from "../pages/Stocks";
import Settings from "../pages/Settings";
import Plans from "../pages/Plans";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/users",
    element: <Users />,
  },
  {
    path: "/signals",
    element: <MonthlySignals />,
  },
  {
    path: "/stocks",
    element: <Stocks />,
  },
  {
    path: "/plans",
    element: <Plans />,
  },
  {
    path: "/settings",
    element: <Settings />,
  },
]);

export default router;
