import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedStocks, setSelectedUsers } from "../../redux/stocksSlice";

const Dropdown = ({ className, heading = "Users" }) => {
  const dispatch = useDispatch();
  const { selectedStocks, selectedUsers } = useSelector(
    (state) => state.stocks
  );
  const [selected, setSelected] = React.useState(
    heading === "Users" ? selectedUsers : selectedStocks
  );

  const options = [
    { value: 0, name: "All" },
    { value: 10, name: "10" },
    { value: 20, name: "20" },
    { value: 30, name: "30" },
  ];

  const handleChange = (event) => {
    setSelected(event.target.value);
    if (heading === "Users") {
      dispatch(setSelectedUsers(event.target.value));
    } else {
      dispatch(setSelectedStocks(event.target.value));
    }
  };

  return (
    <FormControl>
      <InputLabel id="demo-simple-select-label">{heading}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        onChange={handleChange}
        sx={{
          height: "40px",
          width: "280px",
        }}
        value={selected}
        label={heading}
        className={className}
        IconComponent={KeyboardArrowDownIcon}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default Dropdown;
