import { useTheme } from "@emotion/react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { Typography } from "@mui/material";
import stockIcon from "../../assets/images/stockIcon.svg";
import { removeStock } from "../../redux/stocksSlice";

const Stocks = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { stockDetails } = useSelector((state) => state.stocks);

  return (
    <div className="mt-6">
      {stockDetails.map((stock, index) => (
        <div
          key={index}
          className={`flex justify-between items-center mt-2 p-2 pl-4 rounded-lg w-96`}
          style={{
            backgroundColor:
              index % 2 === 0
                ? theme.palette.secondary.bgGrey
                : theme.palette.text.white,
          }}
        >
          <div className="flex items-center">
            <img
              src={stock?.iconUrl || stockIcon}
              alt="Icon"
              className="mr-3 w-12"
            />
            <div>
              <Typography className="text-sm">{stock.symbol}</Typography>
              <Typography className="text-xs text-gray-400">
                {stock.name}
              </Typography>
            </div>
          </div>
          <CloseIcon
            onClick={() => dispatch(removeStock(stock.symbol))}
            className="text-lg cursor-pointer"
          />
        </div>
      ))}
    </div>
  );
};

export default Stocks;
